import React from "react";
import "./Header.css";
import rain from "../../assets/mp4/bridge.mp4";
import Resume from "../../assets/misc/Resume.pdf";
import { FaCloudMoonRain } from "react-icons/fa";

export default function Header() {
  return (
    <section className="header-container" id="home">
      <div className="scroll-background">
        <video autoPlay muted loop>
          <source src={rain} type="video/mp4" />
        </video>
      </div>
      <div className="content">
        <div className="custom-container">
          <FaCloudMoonRain size={200} style={{ color: "white" }} />
          <div className="first-word">Rainy Day Games</div>
          <div className="second-word">Coming Soon</div>
        </div>
      </div>
    </section>
  );
}
